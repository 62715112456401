import { graphql, PageRendererProps, useStaticQuery } from "gatsby"
import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { MarkdownRemark } from "../graphql-types"
import Link from "gatsby-link"
import styled from '@emotion/styled'
import { theme } from "../components/styles"

type Props = PageRendererProps

const Article = styled.div`
 margin-bottom: 4em;
`

const ArticleHeading = styled.h2`
 margin-bottom: .2em;
  a {
    text-decoration: none;
    color: ${theme.colors.gray};
    &:hover {
      color: ${theme.colors.purple};
    }
  }
`

const Date = styled.div`
 font-size: 14px;
 margin-bottom: 1em;
`

const LinkArticle = styled(Link)`
 font-size: 14px;
`

const BlogIndex = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD. MM. YYYY")
              title
              description
            }
          }
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title="Všechny články"
        keywords={[`nazavody.cz`, `registrace`, `organizace`, `závody`, `blog`]}
      />
      {posts.map(({ node }: { node: MarkdownRemark }) => {
        const frontmatter = node!.frontmatter!
        const fields = node!.fields!
        const slug = fields.slug!
        const excerpt = node!.excerpt!

        const title = frontmatter.title || fields.slug
        return (
          <Article key={slug}>
            <ArticleHeading>
              <Link to={slug}>{title}</Link>
            </ArticleHeading>
            <Date>{frontmatter.date}</Date>
            <p
              dangerouslySetInnerHTML={{
                __html: frontmatter.description || excerpt,
              }}
            />
            <LinkArticle to={slug}>Celý článek</LinkArticle>
          </Article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex
